<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/comment'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 row">
                <!-- TextBox -->

                <div class="col-lg-6 border-col" v-for="item in 20" :key="item">
                  <div class="row">
                    <div class="col-6">
                      <label :for="'commentTitle' + item"
                        >Name {{ item }}
                      </label>
                      <input
                        v-model="formData['commentTitle' + item]"
                        type="text"
                        class="form-control"
                      />
                    </div>

                    <div class="mb-3 mb-0 col-6">
                      <label :for="'commentOperation' + item"
                        >Operation {{ item }}
                      </label>
                      <input
                        v-model="formData['commentOperation' + item]"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <label>Profile Photo </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('commentImage' + item, $event)"
                      />
                    </div>
                    <div class="mb-3 mb-0 col-12">
                      <label :for="'commentDescription' + item">
                        Comment {{ item }} (max 300 Character)
                      </label>
                      <textarea
                        class="form-control"
                        v-model="formData['commentDescription' + item]"
                        rows="4"
                        maxlength="300"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!-- TextBox end -->
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Comment Update",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Comment Update",
      items: [
        {
          text: "Comment",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        commentTitle1: null,
        commentOperation1: null,
        commentImage1: null,
        commentDescription1: null,
        commentTitle2: null,
        commentOperation2: null,
        commentImage2: null,
        commentDescription2: null,
        commentTitle3: null,
        commentOperation3: null,
        commentImage3: null,
        commentDescription3: null,
        commentTitle4: null,
        commentOperation4: null,
        commentImage4: null,
        commentDescription4: null,
        commentTitle5: null,
        commentOperation5: null,
        commentImage5: null,
        commentDescription5: null,
        commentTitle6: null,
        commentOperation6: null,
        commentImage6: null,
        commentDescription6: null,
        commentTitle7: null,
        commentOperation7: null,
        commentImage7: null,
        commentDescription7: null,
        commentTitle8: null,
        commentOperation8: null,
        commentImage8: null,
        commentDescription8: null,
        commentTitle9: null,
        commentOperation9: null,
        commentImage9: null,
        commentDescription9: null,
        commentTitle10: null,
        commentOperation10: null,
        commentImage10: null,
        commentDescription10: null,
        commentTitle11: null,
        commentOperation11: null,
        commentImage11: null,
        commentDescription11: null,
        commentTitle12: null,
        commentOperation12: null,
        commentImage12: null,
        commentDescription12: null,
        commentTitle13: null,
        commentOperation13: null,
        commentImage13: null,
        commentDescription13: null,
        commentTitle14: null,
        commentOperation14: null,
        commentImage14: null,
        commentDescription14: null,
        commentTitle15: null,
        commentOperation15: null,
        commentImage15: null,
        commentDescription15: null,
        commentTitle16: null,
        commentOperation16: null,
        commentImage16: null,
        commentDescription16: null,
        commentTitle17: null,
        commentOperation17: null,
        commentImage17: null,
        commentDescription17: null,
        commentTitle18: null,
        commentOperation18: null,
        commentImage18: null,
        commentDescription18: null,
        commentTitle19: null,
        commentOperation19: null,
        commentImage19: null,
        commentDescription19: null,
        commentTitle20: null,
        commentOperation20: null,
        commentImage20: null,
        commentDescription20: null,
      },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.Comment.UpdateList + this.$route.params.id).then(
        (response) => {
          if (response.data.status == "success") {
            console.log(response.data.order);

            for (const i in response.data.order) {
              this.formData["commentTitle" + i] =
                response.data.order[i]["title"];
              this.formData["commentOperation" + i] =
                response.data.order[i]["operation"];
              this.formData["commentDescription" + i] =
                response.data.order[i]["description"];
            }
          }
        }
      );
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Comment.Update + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/comment");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
